@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Signika', sans-serif;
  letter-spacing: 1px;
}

.app-cont {
  width: 85%;
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

h3 {
  color: #C74B02;
  padding-bottom: 10px;
  font-size: 2rem;
}

p {
  color: rgba(255, 255, 255, 0.753);
}

a {
  color: rgba(255, 255, 255, 0.753);
}

a:hover {
  color: #fff;
}