.title-cont {
  position: absolute;
  bottom: 25vh;
  color: #C74B02;
  display: flex;
  flex-direction: column;
  cursor: default;
  margin-left: 40px;
  z-index: 60;
}

.title-cont h1 {
  font-size: 11rem;
  line-height: 0.9;
}

.title-cont h2 {
  font-size: 2rem;
  align-self: flex-end;
}

@media (max-width: 1210px) {
  .title-cont h1 {
    font-size: 8rem;
  }
  .title-cont h2 {
    font-size: 1.7rem;
  }
  .title-cont {
    margin-left: 0;
  }
}

@media (max-width: 920px) {
  .title-cont h1 {
    font-size: 6rem;
  }
  .title-cont h2 {
    font-size: 1.3rem;
  }
  .title-cont {
    margin-left: 0;
  }
}

@media (max-width: 730px) {
  .title-cont {
    bottom: 70vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-cont h2 {
    align-self: center;
  }
  .title-cont h1 {
    font-size: 5.5rem;
  }
  .title-cont h2 {
    margin-top: 5px;
    font-size: 1.2rem;
  }
  .title-cont {
    margin-left: 0;
  }
}
