.bg-image {
  background-image: url(../../images/genial-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-div {
  height: 100vh;
}

@media (max-width: 730px) {
  .empty-div {
    background: linear-gradient(black, 85%, rgb(70, 70, 70)); 
    z-index: 50;
    transition: 0.2s linear;
  }
}