main {
  background: linear-gradient(#000, 95%, grey); 
}

@media (max-width: 730px) {
  main {
    background: linear-gradient(#000, 95%, rgb(70, 70, 70)); 
  }
}

