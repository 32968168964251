@import url(https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Signika', sans-serif;
  letter-spacing: 1px;
}

.app-cont {
  width: 85%;
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

h3 {
  color: #C74B02;
  padding-bottom: 10px;
  font-size: 2rem;
}

p {
  color: rgba(255, 255, 255, 0.753);
}

a {
  color: rgba(255, 255, 255, 0.753);
}

a:hover {
  color: #fff;
}
.bg-image {
  background-image: url(/static/media/genial-bg.ec15b98d.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-div {
  height: 100vh;
}

@media (max-width: 730px) {
  .empty-div {
    background: linear-gradient(black, 85%, rgb(70, 70, 70)); 
    z-index: 50;
    transition: 0.2s linear;
  }
}
.title-cont {
  position: absolute;
  bottom: 25vh;
  color: #C74B02;
  display: flex;
  flex-direction: column;
  cursor: default;
  margin-left: 40px;
  z-index: 60;
}

.title-cont h1 {
  font-size: 11rem;
  line-height: 0.9;
}

.title-cont h2 {
  font-size: 2rem;
  align-self: flex-end;
}

@media (max-width: 1210px) {
  .title-cont h1 {
    font-size: 8rem;
  }
  .title-cont h2 {
    font-size: 1.7rem;
  }
  .title-cont {
    margin-left: 0;
  }
}

@media (max-width: 920px) {
  .title-cont h1 {
    font-size: 6rem;
  }
  .title-cont h2 {
    font-size: 1.3rem;
  }
  .title-cont {
    margin-left: 0;
  }
}

@media (max-width: 730px) {
  .title-cont {
    bottom: 70vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-cont h2 {
    align-self: center;
  }
  .title-cont h1 {
    font-size: 5.5rem;
  }
  .title-cont h2 {
    margin-top: 5px;
    font-size: 1.2rem;
  }
  .title-cont {
    margin-left: 0;
  }
}

nav {
  position: absolute;
  bottom: 20vh;
  z-index: 60;
  text-align: center;
  left: 50%;
  width: 70%;
  transform: translateX(-50%);
}

nav li {
  margin: 20px 0;
  list-style: none;
  border: 1px solid #C74B02;
}

nav a {
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 11px 50px;
  background-color: #000;
}

nav a:hover {
  color: rgba(255, 255, 255, 0.753);
}
.arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  cursor: pointer;
  animation: arrow 0.8s infinite alternate ease-in-out;
}

@keyframes arrow {
  0% {
        transform: translate(-50%, 0); 
        opacity: 0.4;
      }
  100% {
    transform: translate(-50%, 0.7em);
    opacity: 0.9;
  }    
}

@media (max-width: 730px){
  .arrow {
    display: none;
  }
}


.about-cont {
  padding-top: 55px;
}

@media (max-width: 660px) {
  .about-cont {
    padding-top: 30px;
  }
}
.work-cont {
  padding-top: 40px;
}

.work-gallery-cont {
  padding-top: 45px;
  padding-bottom: 120px; 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

@media (max-width: 1300px) {
  .work-gallery-cont {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
}

@media (max-width: 730px) {
  .work-gallery-cont {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
} 

@media (max-width: 660px) {
  .work-cont {
    padding-top: 10px;
  }
  .work-gallery-cont {
    grid-template-columns: 1fr;
    padding-bottom: 80px;
  }
}
.grid figure {
	position: relative;
	overflow: hidden;
	min-width: 250px;
	max-height: 330px;
	text-align: left;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-image: linear-gradient(to bottom, rgba(199, 75, 2, 1), 40%, rgba(199, 75, 2, 0));
}

@media (min-width: 731px) {
  figure:hover .overlay {
    opacity: 0.8;
  }
}

.grid figure figcaption {
	padding: 2em;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure.effect figcaption {
	top: auto;
	bottom: 0;
	padding: 0.5em 0.65em;
	height: 4.75em;
	background: rgb(100, 100, 100);
	transition: transform 0.35s;
	transform: translate3d(0,100%,0);
  box-shadow: 0px -4px 0px 0px #C74B02;
}

figure.effect:hover figcaption {
	transform: translate3d(0,0,0);
}

figcaption h5 {
  padding-bottom: 5px;
  color: #fff;
}

figcaption p {
  color: rgba(255, 255, 255, 0.753);
}

@media (max-width: 1300px) {
  .grid figure {
    max-width: 500px;
    max-height: 400px;
  }
}

@media (max-width: 730px) {
  .grid figure {
    max-width: 700px;
    max-height: 600px;
    overflow: visible;
  }
  figure.effect figcaption {
    transform: translate3d(0,0%,0);
  }
} 

@media (max-width: 600px) {
  figure.effect figcaption {
    padding: 0.4em 0.6em;
  }
  figcaption h5 {
    padding-bottom: 3px;
  }
}



main {
  background: linear-gradient(#000, 95%, grey); 
}

@media (max-width: 730px) {
  main {
    background: linear-gradient(#000, 95%, rgb(70, 70, 70)); 
  }
}


.top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  opacity: 0.6;
  z-index: 200;
  animation: up 1s infinite alternate ease-in-out;
  cursor: pointer;
}

@keyframes up {
  0% {
        transform: translate(0, 0); 
      }
  100% {
    transform: translate(0, 0.5em);
  }    
}

@media (min-width: 1200px) {
  .top {
    width: 70px;
    height: 70px;
  }
  .top:hover {
    opacity: 0.8;
  }
}
footer {
  background-color: #000;
  color: rgba(255, 255, 255, 0.753)
}
.contact-cont {
  padding-top: 30px;
}

.contact-details-cont {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.enquiry-cont p {
  padding-bottom: 8px;
}

.enquiry-cont a {
  padding: 4px 0;
}

.phone-cont, .email-cont {
  display: flex;
}

.icon {
  margin-right: 12px;
  vertical-align: bottom;
}

.credit {
  text-align: center;
  padding-bottom: 35px;
  font-size: 0.8rem;
}

@media (max-width: 660px) {
  .contact-details-cont {
    flex-direction: column;
    padding-bottom: 40px;
  }
  .enquiry-cont {
    order: -1;
    padding-bottom: 25px;
  }
  .enquiry-cont p {
    padding-bottom: 10px;
  }
}
