.grid figure {
	position: relative;
	overflow: hidden;
	min-width: 250px;
	max-height: 330px;
	text-align: left;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-image: linear-gradient(to bottom, rgba(199, 75, 2, 1), 40%, rgba(199, 75, 2, 0));
}

@media (min-width: 731px) {
  figure:hover .overlay {
    opacity: 0.8;
  }
}

.grid figure figcaption {
	padding: 2em;
	font-size: 1.25em;
	backface-visibility: hidden;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure.effect figcaption {
	top: auto;
	bottom: 0;
	padding: 0.5em 0.65em;
	height: 4.75em;
	background: rgb(100, 100, 100);
	transition: transform 0.35s;
	transform: translate3d(0,100%,0);
  box-shadow: 0px -4px 0px 0px #C74B02;
}

figure.effect:hover figcaption {
	transform: translate3d(0,0,0);
}

figcaption h5 {
  padding-bottom: 5px;
  color: #fff;
}

figcaption p {
  color: rgba(255, 255, 255, 0.753);
}

@media (max-width: 1300px) {
  .grid figure {
    max-width: 500px;
    max-height: 400px;
  }
}

@media (max-width: 730px) {
  .grid figure {
    max-width: 700px;
    max-height: 600px;
    overflow: visible;
  }
  figure.effect figcaption {
    transform: translate3d(0,0%,0);
  }
} 

@media (max-width: 600px) {
  figure.effect figcaption {
    padding: 0.4em 0.6em;
  }
  figcaption h5 {
    padding-bottom: 3px;
  }
}


