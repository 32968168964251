.contact-cont {
  padding-top: 30px;
}

.contact-details-cont {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.enquiry-cont p {
  padding-bottom: 8px;
}

.enquiry-cont a {
  padding: 4px 0;
}

.phone-cont, .email-cont {
  display: flex;
}

.icon {
  margin-right: 12px;
  vertical-align: bottom;
}

.credit {
  text-align: center;
  padding-bottom: 35px;
  font-size: 0.8rem;
}

@media (max-width: 660px) {
  .contact-details-cont {
    flex-direction: column;
    padding-bottom: 40px;
  }
  .enquiry-cont {
    order: -1;
    padding-bottom: 25px;
  }
  .enquiry-cont p {
    padding-bottom: 10px;
  }
}